import { useEffect, useRef } from 'react'

export type RequestIdleCallbackHandle = number

declare global {
    interface Window {
        requestIdleCallback: (callback: IdleRequestCallback, options?: IdleRequestOptions) => RequestIdleCallbackHandle
        cancelIdleCallback: (handle: RequestIdleCallbackHandle) => void
    }
}

const supportsRequestIdleCallback_ = typeof window !== 'undefined' && typeof window.requestIdleCallback === 'function'

/**
 *  loosely based on: https://github.com/EricLambrecht/react-timing-hooks/blob/master/src/idle-callback/useIdleCallback.ts
 * @param callback The callback that is invoked as soons as the browser invokes the idle callback
 * @param options Options for requestIdleCallback
 */
export const useIdleCallback = (fn: () => void, options?: IdleRequestOptions) => {
    const callback = useRef(fn)
    const handle = useRef<number | undefined>()

    // if the provided function changes, change its reference
    useEffect(() => {
        if (typeof fn === 'function') {
            callback.current = fn
        }
    }, [callback, fn])

    useEffect(() => {
        if (supportsRequestIdleCallback_) {
            handle.current = window.requestIdleCallback(callback.current, options)
        }

        return () => {
            if (handle.current) {
                window.cancelIdleCallback(handle.current)
            }
        }
    }, [handle, options])
}
