import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { BrightSkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { css } from 'styled-components'
import { system, ValueObject } from '@wh-components/system'

const containIntrinsicSize = system({
    height: {
        property: 'contain-intrinsic-size',
    },
})

export const ResultListAdInMotionSkeleton: FunctionComponent<{ className?: string } & { height: ValueObject<number> }> = ({
    height,
    className,
}) => (
    <Box
        display="flex"
        height={height}
        gap="xs"
        flexDirection="column"
        marginHorizontal={{ phone: 'm', tablet: 0 }}
        paddingHorizontal={{ tablet: 'm' }}
        paddingVertical="m"
        borderBottom="owl"
        className={className}
        css={css`
            content-visibility: auto;
            ${containIntrinsicSize}
        `}
    >
        <Box height={{ phone: 250, tablet: 300, desktop: 360 }} width="100%">
            <BrightSkeletonLine />
        </Box>
        <Box height={22} width="100%">
            <BrightSkeletonLine />
        </Box>
        <Box height={18} width="60%">
            <BrightSkeletonLine />
        </Box>
        <Box height={25} width="70%">
            <BrightSkeletonLine />
        </Box>
        <Box
            display="flex"
            gap={{ phone: 'xs', desktop: 0 }}
            flexDirection={{ phone: 'column-reverse', desktop: 'row' }}
            alignItems={{ desktop: 'flex-end' }}
            justifyContent="space-between"
        >
            <Box display="flex" gap="xs" flexDirection="column" alignItems={{ desktop: 'flex-end' }} justifyContent="space-between">
                <Box height={13} width={150}>
                    <BrightSkeletonLine />
                </Box>
                {(height.phone ?? 0) > 410 && (
                    <Box height={13} width={150}>
                        <BrightSkeletonLine />
                    </Box>
                )}
            </Box>
            <Box height={{ phone: 22, desktop: 26 }} width={{ phone: 90, desktop: 110 }}>
                <BrightSkeletonLine />
            </Box>
        </Box>
    </Box>
)
