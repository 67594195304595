import React, { Fragment, FunctionComponent } from 'react'
import {
    CreateSearchAgentButton,
    CreateSearchAgentButtonProps,
} from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButton'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { useTheme } from 'styled-components'

export const StickyCreateSearchAgentButton: FunctionComponent<CreateSearchAgentButtonProps & BoxProps> = ({
    searchAgentCreateLink,
    searchAgentOptionsLink,
    setSearchAgentUIState,
    testId,
    clickActionEvent,
    taggingData,
    ...props
}) => {
    const themeFromContext = useTheme()

    return (
        <Fragment>
            <Box
                display={{ phone: 'flex', tablet: 'none' }}
                zIndex={themeFromContext.zIndices.stickyContent}
                position="sticky"
                left="0"
                right="0"
                bottom="20px"
                justifyContent="center"
                {...props}
            >
                <Box width="200px">
                    <CreateSearchAgentButton
                        searchAgentCreateLink={searchAgentCreateLink}
                        searchAgentOptionsLink={searchAgentOptionsLink}
                        setSearchAgentUIState={setSearchAgentUIState}
                        size="small"
                        testId={testId}
                        clickActionEvent={clickActionEvent}
                        taggingData={taggingData}
                        optimizelyTrackEvent="bbx_bottom_sticky_button_create_search_agent_small"
                    />
                </Box>
            </Box>
        </Fragment>
    )
}
