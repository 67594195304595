import React, { FunctionComponent } from 'react'
import { ItemList, WithContext, ListItem } from 'schema-dts'
import { captureException } from '@wh/common/chapter/components/Sentry/sentry'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { getSeoUrl } from '../../lib/getSeoUrl'
import { JsonLdScriptElement } from '@wh/common/chapter/components/JsonLd/JsonLdScriptElement'

interface ItemListLdProps {
    searchResult: SearchResult | undefined
}

export const ItemListLd: FunctionComponent<ItemListLdProps> = ({ searchResult }) => {
    try {
        if (searchResult) {
            const itemListElement: ListItem[] = searchResult.advertSummaryList.advertSummary.map((advert, index) => ({
                '@type': 'ListItem',
                position: index,
                url: getSeoUrl(advert),
            }))

            const data: WithContext<ItemList> = {
                '@context': 'https://schema.org',
                '@type': 'ItemList',
                itemListElement: itemListElement,
                // itemListOrder: 'ItemListOrderAscending' - 'ItemListOrderDescending' - 'ItemListUnordered'
                numberOfItems: searchResult.rowsFound,
                name: searchResult.seoMetaData ? searchResult.seoMetaData.title : searchResult.heading,
                description: searchResult.seoMetaData ? searchResult.seoMetaData.description : searchResult.description,
            }
            return <JsonLdScriptElement key="jsonld-itemlist" jsonLd={data} />
        }

        return null
    } catch (error) {
        captureException(error)
        return null
    }
}
