import React from 'react'
import { Modal } from '@wh-components/core/Modal/Modal'
import { TestProps } from '@wh-components/core/common'
import { ResponsiveValue } from '@wh-components/system'

interface InfoModalProps {
    isOpen: boolean
    onRequestClose: () => void
    header: string
    fullScreen?: ResponsiveValue<boolean>
    children: React.ReactNode
    bottomSticky?: React.ReactNode
}

export const InfoModal = ({ isOpen, onRequestClose, header, fullScreen, children, testId, bottomSticky }: InfoModalProps & TestProps) => {
    return (
        <Modal
            header={header}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            fullScreen={fullScreen}
            testId={testId}
            footer={bottomSticky}
        >
            {children}
        </Modal>
    )
}
