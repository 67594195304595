import { JobsWidgetType } from '@bbx/common/types/UserFeed'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { NextRequest } from '@wh/common/chapter/types/nextJS'
import { getDmpSegmentsFromCookie } from '@bbx/search-journey/common/lib/getDmpSegmentsFromCookie'
import { fetcher, getCsrfHeader } from '@wh/common/chapter/api/fetcher'

export const getJobsWidgetFromCookie = (request?: NextRequest): Promise<JobsWidgetType | undefined> => {
    const dmpSegments = getDmpSegmentsFromCookie(request)

    if (dmpSegments.length === 0) {
        return Promise.resolve(undefined)
    }

    return getJobsWidget(dmpSegments, request)
}

export const getJobsWidget = (dmpSegments: number[], request?: NextRequest): Promise<JobsWidgetType> => {
    return fetcher<JobsWidgetType>('/cdc/jobsrecommendations', {
        method: 'POST',
        credentials: 'include',
        cookies: getBbxCookiesFromRequest(request),
        body: JSON.stringify({ dmpSegmentIds: dmpSegments }),
        headers: getCsrfHeader(request),
    })
}
