import React, { FunctionComponent, useContext } from 'react'
import { TestProps } from '@wh-components/core/common'
import { SpaceProps } from '@wh-components/system/space'
import { DisplayProps } from '@wh-components/system/layout'
import { PositionProps } from '@wh-components/system/position'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { ResultListSavedAdButtonContext } from './ResultListSavedAdButtonContext'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { SaveAdButton } from '@bbx/search-journey/common/components/SaveAd/SaveAdButton'
import { IconButton } from '@wh-components/core/Button/Button'
import FavoriteIcon from '@wh-components/icons/Favorite'
import FavoriteEmptyIcon from '@wh-components/icons/FavoriteEmpty'
import { increaseClickableArea } from '@wh/common/chapter/components/increaseClickableArea'

interface ResultListSaveAdButtonProps extends TestProps, SpaceProps, DisplayProps, PositionProps {
    adId: string
    adTitle: string
    taggingData: TaggingData
}

export const ResultListSaveAdButton: FunctionComponent<ResultListSaveAdButtonProps> = ({ adId, adTitle, taggingData, ...props }) => {
    const [savedInFolder, setSavedInFolder] = useContext(ResultListSavedAdButtonContext)
    const isSavedbyId = savedInFolder[adId] || false
    const { trackCustomDmpEvent } = useContext(DmpStateContext)

    return (
        <SaveAdButton
            adId={adId}
            adTitle={adTitle}
            isSaved={isSavedbyId}
            setIsSaved={(isSaved) => setSavedInFolder({ id: adId, status: isSaved })}
            onClickTaggingAction={() => {
                if (!isSavedbyId) {
                    trackCustomDmpEvent('UserAction', { eventName: 'TapSaveAd' })
                }
                return isSavedbyId
                    ? callActionEvent('search_result_list_ad_click_unsave', taggingData)
                    : callActionEvent('search_result_list_ad_click_save', taggingData)
            }}
            onSavedTaggingAction={() => callActionEvent('search_result_list_ad_saved', taggingData, { favorite_ad: adId })}
            onUnsavedTaggingAction={() => callActionEvent('search_result_list_ad_unsaved', taggingData, { favorite_ad: adId })}
        >
            <IconButton
                size="small"
                variant={isSavedbyId ? 'solid' : 'outline'}
                Icon={isSavedbyId ? FavoriteIcon : FavoriteEmptyIcon}
                aria-label="Anzeige Merken"
                aria-pressed={isSavedbyId}
                css={increaseClickableArea(10)}
                {...props}
            />
        </SaveAdButton>
    )
}
