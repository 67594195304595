import React, { FunctionComponent, RefObject } from 'react'
import { ResultListHeaderStickyMediumLarge } from './ResultListHeaderStickyMediumLarge'
import { ResultListHeaderStickySmall } from './ResultListHeaderStickySmall'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { formatNumber } from '@wh/common/chapter/lib/formatter'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { SearchAgentUIState } from '../../common/CreateSearchAgentButton/CreateSearchAgentButton'
import { SearchCallback } from '../../common/SearchCallback'

interface ResultListHeaderStickyProps {
    searchResult: SearchResult
    setSearchAgentUIState: (value: SearchAgentUIState) => void
    setShowNavigatorSidebar: (value: boolean) => void
    regularNonStickyHeaderVisibilityTrackingRef: RefObject<HTMLDivElement>
    resultListVisibilityTrackingRef: RefObject<HTMLDivElement>
    onSearch: SearchCallback
}

export const ResultListHeaderSticky: FunctionComponent<ResultListHeaderStickyProps> = ({
    searchResult,
    setSearchAgentUIState,
    setShowNavigatorSidebar,
    regularNonStickyHeaderVisibilityTrackingRef,
    resultListVisibilityTrackingRef,
    onSearch,
}) => {
    const searchAgentCreateLink = findContextLinkWithId('searchAgentCreateLink', searchResult.searchContextLinks)
    const searchAgentOptionsLink = findContextLinkWithId('searchAgentOptionsLink', searchResult.searchContextLinks)

    const screenSize = useScreenSize()

    return screenSize !== 'phone' ? (
        searchAgentCreateLink ? (
            <ResultListHeaderStickyMediumLarge
                testId="sticky-header"
                searchAgentCreateLink={searchAgentCreateLink}
                searchAgentOptionsLink={searchAgentOptionsLink}
                setSearchAgentUIState={setSearchAgentUIState}
                regularNonStickyHeaderVisibilityTrackingRef={regularNonStickyHeaderVisibilityTrackingRef}
                resultListVisibilityTrackingRef={resultListVisibilityTrackingRef}
                title={`${formatNumber(searchResult.rowsFound)} Anzeigen`}
                searchResult={searchResult}
                onSearch={onSearch}
            />
        ) : null
    ) : (
        <ResultListHeaderStickySmall
            testIdPrefix="sticky-header-small"
            regularNonStickyHeaderVisibilityTrackingRef={regularNonStickyHeaderVisibilityTrackingRef}
            resultListVisibilityTrackingRef={resultListVisibilityTrackingRef}
            title={`${formatNumber(searchResult.rowsFound)} Anzeigen online`}
            searchResult={searchResult}
            setShowNavigatorSidebar={setShowNavigatorSidebar}
            taggingData={searchResult.taggingData}
        />
    )
}
