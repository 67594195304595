import { ImageScaleModes } from '@wh/common/chapter/components/AdImage'
import { PlaceholderImage } from '@wh/common/chapter/components/PlaceholderImage'
import { hiddenScrollbar } from '@wh/common/chapter/components/hiddenScrollbar'
import { AdType } from '@wh/common/chapter/types/AdType'
import { Box } from '@wh-components/core/Box/Box'
import { VerticalShortName } from '@wh/common/chapter/types/verticals'
import { rgba } from 'polished'
import React, { forwardRef, FunctionComponent, MutableRefObject, RefObject } from 'react'
import { css } from 'styled-components'
import { ImageSrcSetGalleryData } from '../ImageGallery.settings'
import { ResponsiveValue } from '@wh-components/system'
import { useVanillaImageGallery } from '@bbx/search-journey/common/components/ImageGallery/VanillaImageGallery/useVanillaImageGallery'
import { ArrowIconButton, HoverContainer } from '@bbx/search-journey/common/components/ImageGallery/VanillaImageGallery/ArrowIconButton'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { Picture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'

const IMAGE_HEIGHT = { phone: '250px', tablet: '300px', desktop: '360px' }

export type VanillaImageGalleryProps = {
    imageData: ImageSrcSetGalleryData[]
    eventHandlers: EventHandlers
    imageScaleMode?: ImageScaleModes
    adType?: AdType
    vertical?: VerticalShortName
    isHouse?: boolean
    imageHeight?: ResponsiveValue<string>
    desktopNrOfShownImages?: number
    showCounter?: boolean
    slideInterval?: number
    imageBackgroundColor?: string
}

type EventHandlers = {
    onClick: (imageGalleryData: ImageSrcSetGalleryData) => void
    onManualChange?: () => void
}

export const VanillaImageGallery: FunctionComponent<VanillaImageGalleryProps> = ({
    imageData,
    eventHandlers,
    imageScaleMode = 'contain',
    adType,
    vertical,
    isHouse,
    imageHeight = IMAGE_HEIGHT,
    desktopNrOfShownImages = 1,
    showCounter = true,
    imageBackgroundColor,
    slideInterval = 2000,
}) => {
    const { scrollContainerRef, imageRef, interval, handlePictureChange } = useVanillaImageGallery(
        imageData.length,
        slideInterval,
        eventHandlers.onManualChange,
    )

    return (
        <HoverContainer position="relative">
            <ArrowIconButton
                direction="left"
                onClick={() => {
                    handlePictureChange('previous')
                    clearInterval(interval.current)
                    eventHandlers.onManualChange?.()
                }}
                testId="button-image-previous"
            />

            {imageData.length === 0 ? (
                <Box height={imageHeight} width="auto">
                    <PlaceholderImage vertical={vertical} adType={adType} isHouse={isHouse} />
                </Box>
            ) : (
                <ImageContainer
                    ref={scrollContainerRef}
                    imageData={imageData}
                    imageScaleMode={imageScaleMode}
                    imageHeight={imageHeight}
                    desktopNrOfShownImages={desktopNrOfShownImages}
                    showCounter={showCounter}
                    imageBackgroundColor={imageBackgroundColor}
                    imageRef={imageRef}
                    {...eventHandlers}
                />
            )}

            <ArrowIconButton
                direction="right"
                onClick={() => {
                    handlePictureChange('next')
                    clearInterval(interval.current)
                    eventHandlers.onManualChange?.()
                }}
                testId="button-image-next"
            />
        </HoverContainer>
    )
}

type ImageContainerProps = {
    imageData: ImageSrcSetGalleryData[]
    imageScaleMode: ImageScaleModes
    imageHeight: ResponsiveValue<string>
    desktopNrOfShownImages?: number
    showCounter: boolean
    imageBackgroundColor?: string
    imageRef: MutableRefObject<HTMLDivElement | undefined | null>
} & EventHandlers

const ImageContainer = forwardRef(
    (
        {
            imageData,
            imageScaleMode,
            onClick,
            imageHeight,
            desktopNrOfShownImages,
            showCounter,
            imageBackgroundColor,
            imageRef,
        }: ImageContainerProps,
        ref,
    ) => {
        return (
            <Box
                ref={ref as RefObject<HTMLDivElement>}
                overflowX="scroll"
                overflowY="hidden"
                display="flex"
                height={imageHeight}
                width="100%"
                testId="image-container"
                css={css`
                    scroll-snap-type: x mandatory;
                    scroll-behavior: smooth;
                    counter-reset: picture;

                    ${hiddenScrollbar}
                `}
            >
                {imageData.map((image, index) => {
                    return (
                        <Box
                            key={index}
                            position="relative"
                            width={{ phone: '100%', tablet: `calc(100% / ${desktopNrOfShownImages})` }}
                            height="100%"
                            ref={index === 0 ? (imageRef as RefObject<HTMLDivElement>) : undefined}
                            css={css`
                                flex-shrink: 0;
                                scroll-snap-align: start;
                                scroll-snap-stop: always;
                                ${showCounter &&
                                css`
                                    &:before {
                                        counter-increment: picture;
                                        content: counter(picture) ' / ${imageData.length}';
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        color: ${({ theme }) => theme.colors.palette.white};
                                        background-color: ${({ theme }) => rgba(theme.colors.palette.koala, 0.6)};
                                        padding: ${({ theme }) => theme.space.xs}px ${({ theme }) => theme.space.s}px;
                                        border-bottom-right-radius: 6px;
                                    }
                                `}
                            `}
                        >
                            <ClientRoutingAnchorLink
                                type="anchor"
                                href={image.link}
                                onClick={() => {
                                    onClick(image)
                                }}
                                display="flex"
                                justifyContent="center"
                                width="100%"
                                height="100%"
                            >
                                <Box display="block" backgroundColor={imageBackgroundColor} width="100%">
                                    {typeof image.imageUrl === 'string' ? (
                                        <ResponsiveImage
                                            src={image.imageUrl}
                                            alt={image.alt}
                                            cssHeight="100%"
                                            cssWidth="100%"
                                            objectFit={imageScaleMode}
                                        />
                                    ) : (
                                        <Picture
                                            imgSrcSet={image.imageUrl}
                                            alt={image.alt}
                                            css={css`
                                                width: 100%;
                                                height: 100%;
                                                object-fit: ${imageScaleMode};
                                            `}
                                        />
                                    )}
                                </Box>
                            </ClientRoutingAnchorLink>
                        </Box>
                    )
                })}
            </Box>
        )
    },
)
