import React, { FunctionComponent, PropsWithChildren, useMemo } from 'react'
import { SavedAdsAdIdsRecord } from '@bbx/common/types/savedAds'

type UpdateSavedAdsIdList = ({ id, status }: { id: string; status: boolean }) => void
type ContextProps = [SavedAdsAdIdsRecord, UpdateSavedAdsIdList]

const dummySetState: ({ id, status }: { id: string; status: boolean }) => void = () => {}

export const ResultListSavedAdButtonContext = React.createContext<ContextProps>([{}, dummySetState])

export const ResultListSaveAdButtonProvider: FunctionComponent<
    PropsWithChildren<{
        savedAdsIdList: SavedAdsAdIdsRecord
        updateSavedAdsIdList: UpdateSavedAdsIdList
    }>
> = ({ savedAdsIdList, updateSavedAdsIdList, children }) => {
    const value = useMemo<[SavedAdsAdIdsRecord, UpdateSavedAdsIdList]>(
        () => [savedAdsIdList, updateSavedAdsIdList],
        [savedAdsIdList, updateSavedAdsIdList],
    )

    return <ResultListSavedAdButtonContext.Provider value={value}>{children}</ResultListSavedAdButtonContext.Provider>
}
