import React, { FunctionComponent, useCallback, useRef } from 'react'
import { css } from 'styled-components'
import { TestProps } from '@wh-components/icons/utilities/createSvgIcon'
import { SpaceProps } from '@wh-components/system/space'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { inverseVerticalIdMap, VerticalId } from '@wh/common/chapter/types/verticals'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { PlaceholderImage } from '@wh/common/chapter/components/PlaceholderImage'
import { SkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { AdvertSummary, AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { Box } from '@wh-components/core/Box/Box'
import { Card } from '@wh-components/core/Card/Card'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import { useResponsiveValue } from '@wh-components/core/utilities/responsive'
import { getTopAdContent, TopAdConfigMap } from './topAdConfig'
import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import { hasPaylivery } from '@bbx/common/lib/isPayliveryAd'
import { baseFontSize } from '@wh-components/core/theme/fontSizeHelper'
import {
    trackTopAdClicked,
    useTrackVisibleTopAds,
} from '@bbx/search-journey/sub-domains/search/components/common/result-list/TopAds/topAdsTagging'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { AspectRatioBox } from '@wh-components/core/AspectRatioBox/AspectRatioBox'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'

interface TopAdsProps extends TestProps, SpaceProps {
    topAds: AdvertSummaryList | undefined
    topAdConfigMap: TopAdConfigMap
    isLoading?: boolean
    taggingData?: TaggingData
    verticalId: VerticalId
}

export const TopAdsLarge: FunctionComponent<TopAdsProps> = ({ topAds, topAdConfigMap, isLoading, taggingData, verticalId, ...props }) => {
    const nrOfAds = useResponsiveValue({ phone: 0, tablet: 2, desktop: 3 }, 3)
    const { visibilityTrackingRef } = useTrackVisibleTopAds(topAds, verticalId)

    return (
        <Card
            display={{ phone: 'none', tablet: 'block' }}
            paddingTop="sm"
            testId="top-ads-large"
            {...props}
            height={{ tablet: `${155 / baseFontSize}rem` }}
            ref={visibilityTrackingRef}
        >
            <Heading text="TOP-ANZEIGEN" level={3} fontSize="m" marginBottom="s" />
            <Box display="flex" gap="m">
                {isLoading
                    ? Array.from({ length: nrOfAds }).map((_, index) => <TopAdSkeleton key={index} />)
                    : topAds?.advertSummary
                          .slice(0, nrOfAds)
                          .map((topAd, index) => (
                              <TopAdLarge
                                  key={`${topAd.id}-${index}`}
                                  index={index}
                                  topAd={topAd}
                                  topAdConfigMap={topAdConfigMap}
                                  taggingData={taggingData}
                                  verticalId={verticalId}
                              />
                          ))}
            </Box>
        </Card>
    )
}

interface TopAdProps extends SpaceProps {
    topAd: AdvertSummary
    index: number
    topAdConfigMap: TopAdConfigMap
    taggingData?: TaggingData
    verticalId: VerticalId
}

export const TopAdLarge: FunctionComponent<TopAdProps> = ({ topAd, index, topAdConfigMap, taggingData, verticalId, ...props }) => {
    const image = topAd.advertImageList.advertImage[0]
    const link = getSeoUrl(topAd)
    const { teaser, price, address } = getTopAdContent(topAd, topAdConfigMap)

    const topAdVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const topAdVisibilityCallback = useCallback(() => {
        const adUuid = getAttributeValue(topAd.attributes.attribute, 'AD_UUID')
        const orgUuid = getAttributeValue(topAd.attributes.attribute, 'ORG_UUID')

        logBdsEvent(topAd.id, 'top-atz-result-page-viewed')
        callActionEvent('search_result_list_home_topad_view', taggingData, {
            ad_id: topAd.id,
            ad_uuid: adUuid,
            org_uuid: orgUuid,
        })
    }, [topAd, taggingData])
    useIntersectionObserver(topAdVisibilityTrackingRef, { triggerOnce: true, threshold: 0.5 }, [topAd], topAdVisibilityCallback)

    return (
        <Box
            minWidth={1}
            position="relative"
            display="flex"
            flex={1}
            flexBasis={{ tablet: '50%', desktop: '33.3%' }}
            tabIndex={0}
            aria-label={`zur Anzeige "${topAd.description}"`}
            testId={`top-ad-${topAd.id}`}
            ref={topAdVisibilityTrackingRef}
            {...props}
            css={css`
                outline-color: ${(p) => p.theme.colors.palette.primary.main};
                outline-offset: ${(p) => p.theme.space.xs}px;
            `}
        >
            <AspectRatioBox width={150} ratio={3 / 2} flexShrink={0} backgroundColor="palette.babyseal" marginRight="s">
                {image ? (
                    <ResponsiveImage src={image.mainImageUrl} alt={image.description ?? topAd.description} objectFit="contain" />
                ) : (
                    <Box>
                        <PlaceholderImage vertical={inverseVerticalIdMap[topAd.verticalId]} adType={inverseAdTypeIdMap[topAd.adTypeId]} />
                    </Box>
                )}
            </AspectRatioBox>
            <Box minWidth={1} display="flex" flexDirection="column">
                <ClientRoutingAnchorLink
                    type="anchor"
                    href={link}
                    color="palette.verydarkgrey"
                    display="flex"
                    marginBottom="xxs"
                    tabIndex={-1}
                    aria-label={`zur Anzeige "${topAd.description}"`}
                    testId={`top-ad-title-${topAd.id}`}
                    onClick={() => {
                        trackTopAdClicked(topAd, index, taggingData, verticalId)
                    }}
                    css={css`
                        outline: 0;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }
                    `}
                >
                    <Text lineClamp={2}>{topAd.description}</Text>
                </ClientRoutingAnchorLink>
                <Text fontSize="s" fontWeight="bold" truncate={true} testId={`top-ad-teaser-attributes-${topAd.id}`}>
                    {teaser}
                </Text>
                <Box>
                    <Text fontWeight="bold" color="palette.primary.main" testId={`top-ad-price-${topAd.id}`}>
                        {price}
                    </Text>
                    {hasPaylivery(topAd.attributes.attribute) && (
                        <PayliveryBadge id={`top-ad-paylivery-${topAd.id}`} variant="transparent" />
                    )}
                </Box>
                <Box flexGrow={1} />
                <Text fontSize="xs" color="palette.raccoon" truncate={true} testId={`top-ad-address-${topAd.id}`}>
                    {address}
                </Text>
            </Box>
        </Box>
    )
}

const TopAdSkeleton: FunctionComponent<SpaceProps> = ({ ...props }) => (
    <Box height={100} display="flex" flexGrow="1" testId="top-ad-skeleton" {...props}>
        <AspectRatioBox width={150} ratio={3 / 2} flexShrink={0} marginRight="s">
            <SkeletonLine />
        </AspectRatioBox>
        <Box flexGrow="1">
            <Box width="100%" height="18px" marginBottom="xxs">
                <SkeletonLine />
            </Box>
            <Box width="100%" height="18px" marginBottom="xs">
                <SkeletonLine />
            </Box>
            <Box width="90%" height="18px" marginBottom="xxs">
                <SkeletonLine />
            </Box>
            <Box width="40%" height="20px" marginBottom="xxs">
                <SkeletonLine />
            </Box>
            <Box width="75%" height="16px">
                <SkeletonLine />
            </Box>
        </Box>
    </Box>
)
