import React, { FunctionComponent, useCallback, useRef } from 'react'
import { css } from 'styled-components'
import { TestProps } from '@wh-components/icons/utilities/createSvgIcon'
import { SpaceProps } from '@wh-components/system/space'
import { BorderProps } from '@wh-components/system/border'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { inverseVerticalIdMap, VerticalId } from '@wh/common/chapter/types/verticals'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { PlaceholderImage } from '@wh/common/chapter/components/PlaceholderImage'
import { SkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { AdvertSummary, AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { getTopAdContent, TopAdConfigMap } from './topAdConfig'
import { hasPaylivery } from '@bbx/common/lib/isPayliveryAd'
import { PayliveryBadge } from '@wh/common/chapter/components/PayliveryBadge/PayliveryBadge'
import {
    trackTopAdClicked,
    useTrackVisibleTopAds,
} from '@bbx/search-journey/sub-domains/search/components/common/result-list/TopAds/topAdsTagging'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { AspectRatioBox } from '@wh-components/core/AspectRatioBox/AspectRatioBox'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'

interface TopAdsProps extends TestProps, SpaceProps, BorderProps {
    topAds: AdvertSummaryList | undefined
    topAdConfigMap: TopAdConfigMap
    isLoading?: boolean
    taggingData?: TaggingData
    verticalId: VerticalId
}

export const TopAdsSmall: FunctionComponent<TopAdsProps> = ({ topAds, topAdConfigMap, isLoading, taggingData, verticalId, ...props }) => {
    const nrOfAds = 3
    const { visibilityTrackingRef } = useTrackVisibleTopAds(topAds, verticalId)

    return (
        <Box
            display={{ phone: 'flex', tablet: 'none' }}
            padding="m"
            backgroundColor="palette.polarbear"
            overflowX="scroll"
            tabIndex={-1}
            testId="top-ads-small"
            ref={visibilityTrackingRef}
            {...props}
            css={css`
                /* enable momentum/inertia scrolling on iOS */
                -webkit-overflow-scrolling: touch;

                /* fixes missing padding-right when overflow-x scroll */
                &::after {
                    content: '';
                    padding-right: 0.02px; /* smallest size that is cross browser */
                }
            `}
        >
            {isLoading
                ? Array.from({ length: nrOfAds }).map((_, index) => <TopAdSkeleton key={index} />)
                : topAds?.advertSummary
                      .slice(0, nrOfAds)
                      .map((topAd, index) => (
                          <TopAdSmall
                              key={`${topAd.id}-${index}`}
                              index={index}
                              topAd={topAd}
                              topAdConfigMap={topAdConfigMap}
                              taggingData={taggingData}
                              verticalId={verticalId}
                          />
                      ))}
        </Box>
    )
}

interface TopAdProps extends SpaceProps {
    topAd: AdvertSummary
    index: number
    topAdConfigMap: TopAdConfigMap
    taggingData?: TaggingData
    verticalId: VerticalId
}

export const TopAdSmall: FunctionComponent<TopAdProps> = ({ topAd, index, topAdConfigMap, taggingData, verticalId, ...props }) => {
    const image = topAd.advertImageList.advertImage[0]
    const link = getSeoUrl(topAd)
    const { teaser, price, address } = getTopAdContent(topAd, topAdConfigMap)

    const topAdVisibilityTrackingRef = useRef<HTMLDivElement>(null)
    const topAdVisibilityCallback = useCallback(() => {
        const adUuid = getAttributeValue(topAd.attributes.attribute, 'AD_UUID')
        const orgUuid = getAttributeValue(topAd.attributes.attribute, 'ORG_UUID')

        logBdsEvent(topAd.id, 'top-atz-result-page-viewed')
        callActionEvent('search_result_list_home_topad_view', taggingData, {
            ad_id: topAd.id,
            ad_uuid: adUuid,
            org_uuid: orgUuid,
        })
    }, [topAd, taggingData])
    useIntersectionObserver(topAdVisibilityTrackingRef, { triggerOnce: true, threshold: 0.5 }, [topAd], topAdVisibilityCallback)

    return (
        <Box
            width={250}
            position="relative"
            marginRight="m"
            tabIndex={0}
            aria-label={`zur Anzeige "${topAd.description}"`}
            testId={`top-ad-${topAd.id}`}
            ref={topAdVisibilityTrackingRef}
            {...props}
            css={css`
                outline-color: ${(p) => p.theme.colors.palette.primary.main};
                outline-offset: ${(p) => p.theme.space.s}px;
            `}
        >
            <AspectRatioBox width={250} ratio={3 / 2} flexShrink={0} backgroundColor="palette.babyseal" marginBottom="s">
                {image ? (
                    <ResponsiveImage src={image.mainImageUrl} alt={image.description ?? topAd.description} objectFit="contain" />
                ) : (
                    <Box>
                        <PlaceholderImage vertical={inverseVerticalIdMap[topAd.verticalId]} adType={inverseAdTypeIdMap[topAd.adTypeId]} />
                    </Box>
                )}
            </AspectRatioBox>
            <Box
                paddingHorizontal={6}
                borderRadius={8}
                fontSize="xs"
                fontWeight="bold"
                color="palette.white"
                backgroundColor="palette.primary.main"
                position="absolute"
                top="xs"
                left="xs"
            >
                TOP-Anzeige
            </Box>
            <ClientRoutingAnchorLink
                type="anchor"
                href={link}
                color="palette.verydarkgrey"
                underline="none"
                display="flex"
                tabIndex={-1}
                aria-label={`zur Anzeige "${topAd.description}"`}
                testId={`top-ad-title-${topAd.id}`}
                onClick={() => {
                    trackTopAdClicked(topAd, index, taggingData, verticalId)
                }}
                css={css`
                    outline: 0;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }
                `}
            >
                <Text fontSize="l" truncate={true}>
                    {topAd.description}
                </Text>
            </ClientRoutingAnchorLink>
            <Box display="flex" flexDirection="column">
                <Text fontWeight="bold" truncate={true} testId={`top-ad-teaser-attributes-${topAd.id}`}>
                    {teaser}
                </Text>
                <Box>
                    <Text fontSize="l" fontWeight="bold" color="palette.primary.main" testId={`top-ad-price-${topAd.id}`}>
                        {price}
                    </Text>
                    {hasPaylivery(topAd.attributes.attribute) && (
                        <PayliveryBadge id={`top-ad-paylivery-${topAd.id}`} variant="transparent" />
                    )}
                </Box>
                <Text fontSize="xs" color="palette.raccoon" truncate={true} testId={`top-ad-address-${topAd.id}`}>
                    {address}
                </Text>
            </Box>
        </Box>
    )
}

const TopAdSkeleton: FunctionComponent<SpaceProps> = ({ ...props }) => (
    <Box width={250} marginRight="m" testId="top-ad-skeleton" {...props}>
        <AspectRatioBox width={250} ratio={3 / 2} flexShrink={0} marginBottom="s">
            <SkeletonLine />
        </AspectRatioBox>
        <Box>
            <Box width="100%" height="24px" marginBottom="xxs">
                <SkeletonLine />
            </Box>
            <Box width="90%" height="18px" marginBottom="xxs">
                <SkeletonLine />
            </Box>
            <Box width="40%" height="20px" marginBottom="xxs">
                <SkeletonLine />
            </Box>
            <Box width="75%" height="16px">
                <SkeletonLine />
            </Box>
        </Box>
    </Box>
)
