import { SearchAgentNotificationFrequency, SearchAgentNotificationType, SearchAgentType } from '@bbx/common/types/searchAgent'

export const buildInitialSearchAgent = (
    loginId: number,
    description: string,
    isFollowUser: boolean,
    notificationFrequency: SearchAgentNotificationFrequency,
    orgId?: number,
    verticalId?: number,
): SearchAgentType => {
    return {
        id: '0',
        userId: loginId,
        followingUser: isFollowUser,
        orgId: orgId || 0,
        verticalId: verticalId || 0,
        description: description,
        userAlertChannelList: {
            userAlertChannel: [
                {
                    id: SearchAgentNotificationType.EMAIL,
                    activated: true,
                    notificationFrequency: notificationFrequency,
                },
                {
                    id: SearchAgentNotificationType.PUSH,
                    activated: false,
                    notificationFrequency: notificationFrequency,
                },
            ],
        },
    }
}
