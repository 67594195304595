import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'

interface ResultListHeadingProps {
    heading: string
    changeViewButtons?: React.ReactNode
}

export const ResultListHeading: FunctionComponent<ResultListHeadingProps> = ({ heading, changeViewButtons }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginHorizontal={{ phone: 'm', tablet: 0 }}
            marginVertical={{ phone: 's', tablet: 0 }}
            marginBottom={{ tablet: 'm' }}
        >
            <Heading
                testId="result-list-title"
                id="result-list-title"
                level={1}
                fontSize={{ phone: 's', tablet: 'xl' }}
                fontWeight="normal"
                text={heading}
                aria-label={`${heading}. Die Suchergebnisse aktualisieren sich automatisch nach jedem zusätzlich an- oder abgewählten Filter.`}
            />
            <Box display="flex" marginLeft="auto">
                {changeViewButtons && changeViewButtons}
            </Box>
        </Box>
    )
}
