import { useDigitalAdvertising } from '@wh/common/digital-advertising/hooks/useDigitalAdvertising'
import { calculateResultListAdverstisingSlotIdConfigForSearchResult } from '@wh/common/digital-advertising/config'
import { AdvertisingParametersV2 } from '@wh/common/digital-advertising/types/advertising-parametersV2'
import { useEffect, useMemo, useState } from 'react'
import { DmpParameters, DmpUserIdentities } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'

export const useResultListDigitalAdvertising = (
    advertisingParametersV2: AdvertisingParametersV2,
    dmpParameters: DmpParameters | undefined,
    dmpUserIdentities: DmpUserIdentities | undefined,
    page: number,
    searchResultsOnCurrentPage: number,
    preventLoading: boolean,
    viewMode: 'list' | 'grid',
) => {
    const [localAdvertisingParameters, setLocalAdvertisingParameters] = useState<AdvertisingParametersV2>(advertisingParametersV2)
    const [localDmpParameters, setLocalDmpParameters] = useState<DmpParameters | undefined>(dmpParameters)
    const [localDmpUserIdentities, setLocalDmpUserIdentities] = useState<DmpUserIdentities | undefined>(dmpUserIdentities)
    const renderSlotIdConfig = useMemo(
        () => calculateResultListAdverstisingSlotIdConfigForSearchResult(page, searchResultsOnCurrentPage),
        [page, searchResultsOnCurrentPage],
    )

    useEffect(() => {
        if (!preventLoading) {
            setLocalAdvertisingParameters({ ...advertisingParametersV2, viewtype: viewMode })
            setLocalDmpParameters(dmpParameters)
            setLocalDmpUserIdentities(dmpUserIdentities)
        }
    }, [advertisingParametersV2, dmpParameters, dmpUserIdentities, preventLoading, viewMode])

    return useDigitalAdvertising(renderSlotIdConfig, localAdvertisingParameters, localDmpParameters, localDmpUserIdentities)
}
