import { JobsWidgetType } from '@bbx/common/types/UserFeed'
import { JobsWidgetSlider } from '@bbx/search-journey/common/components/Widgets/JobsWidgetSlider'
import { getPulseAdId, TaggingWidgetType, useWidgetTagging } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { Divider } from '@wh-components/core/Divider/Divider'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { useCookieState } from '@wh/common/chapter/hooks/useCookieState'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import React, { Fragment, FunctionComponent, useContext } from 'react'
import { WhitelistedCookieKey } from '@wh/common/chapter/components/CookieProvider/CookieProvider'

dayjs.extend(duration)

interface ResultListJobsWidgetProps {
    jobsWidget: JobsWidgetType
    taggingData: TaggingData
    showBottomDivider?: boolean
    hideWidgetCookieKey?: WhitelistedCookieKey
}

export const ResultListJobsWidget: FunctionComponent<ResultListJobsWidgetProps> = ({
    jobsWidget,
    taggingData,
    showBottomDivider,
    hideWidgetCookieKey = 'bbxHideJobsResultListWidget',
}) => {
    const numberOfAdsLimit = 7
    const numberOfAdsToDisplay = 4

    const { visibilityTrackingRef } = useWidgetTagging({
        ...widgetAndPage,
        adIds: jobsWidget.ads.map((ad) => getPulseAdId(ad)).slice(0, numberOfAdsLimit),
        pulseMetadata: jobsWidget.pulseMetadata,
    })

    const { trackCustomDmpEvent } = useContext(DmpStateContext)
    const [isJobsWidgetHidden, setJobsWidgetHidden] = useCookieState<'true' | 'false'>(hideWidgetCookieKey, 'false')

    if (isJobsWidgetHidden === 'true') {
        return null
    }

    return (
        <Fragment>
            <JobsWidgetSlider
                widget={jobsWidget}
                marginVertical="m"
                paddingLeft={{ phone: 'm', tablet: 0 }}
                onClickAdTagging={(ad, adIndex) => {
                    callActionEvent('ad_widget_ad_click', taggingData, {
                        ...widgetAndPage,
                        adId: getPulseAdId(ad),
                        adIndex: adIndex + 1,
                        listName: 'external-widget:my-job',
                    })
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                onClickShowAllTagging={() => {
                    callActionEvent('ad_widget_show_all_click', taggingData, widgetAndPage)
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                onClickShowAllButtonTagging={() => {
                    callActionEvent('ad_widget_show_all_button_click', taggingData, widgetAndPage)
                    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, widgetAndPage)
                }}
                numberOfAdsLimit={numberOfAdsLimit}
                numberOfAdsToDisplay={numberOfAdsToDisplay}
                visibilityTrackingRef={visibilityTrackingRef}
                onClose={() => {
                    setJobsWidgetHidden(
                        'true',
                        dayjs()
                            .add(dayjs.duration({ months: 1 }))
                            .toDate(),
                    )
                    callActionEvent('search_result_list_hide_jobs_widget_click', taggingData)

                    trackCustomDmpEvent('UserAction', { eventName: 'TapCloseJobWidget' })
                }}
            />
            {showBottomDivider && <Divider />}
        </Fragment>
    )
}

const widgetAndPage = {
    taggingWidgetType: 'JobsWidget' as TaggingWidgetType,
    page: 'ResultList',
    source: 'personal-space',
}
