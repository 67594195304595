import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import TooltipQuestionmark from '@wh-components/icons/TooltipQuestionmark'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import React, { FunctionComponent } from 'react'
import { SpaceProps } from '@wh-components/system/space'

export const SortOrderInfo: FunctionComponent<SpaceProps> = ({ ...props }) => {
    return (
        <Box display="flex" marginLeft="m" flexDirection={{ phone: 'row', tablet: 'row-reverse' }} {...props}>
            <ServerRoutingAnchorLink
                color="palette.elephant"
                type="anchor"
                href="/will/infos-zur-reihung"
                target="_blank"
                display="flex"
                alignItems="center"
            >
                <TooltipQuestionmark size="xsmall" marginRight="xs" opacity={0.66} />
                <Text fontSize="s">Infos zur Reihung der Anzeigen</Text>
            </ServerRoutingAnchorLink>
        </Box>
    )
}
