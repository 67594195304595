import { AttributeName } from '@wh/common/chapter/types/AttributeName'
import {
    AttributesItem,
    FormattedAttributesItem,
    FormattedAttributesList,
    getAttribute,
    getAttributeValue,
} from '@wh/common/chapter/types/Attributes'
import { AdType, AdTypeId, inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { formatAttributes } from '@bbx/common/lib/formattingAttributes'

export type AttributeItemsStringBuilderFn = (attributeItems: (FormattedAttributesItem | undefined)[]) => string

export type TopAdConfig = {
    teaserAttributes: AttributeName[]
    buildStringFromTeaserItems?: AttributeItemsStringBuilderFn
    priceAttributes: AttributeName[]
    buildStringFromPriceItems?: AttributeItemsStringBuilderFn
    addressAttributes: AttributeName[]
    buildStringFromAddressItems?: AttributeItemsStringBuilderFn
}

export type TopAdConfigMap = Partial<Record<AdType, TopAdConfig>>

export const topAddressBuildStringFromAddressItems = ([postcode, country, location]: (AttributesItem | undefined)[]) => {
    const displayLocation = country?.values[0] !== 'Österreich' ? country?.values[0] : location?.values[0]
    return [postcode?.values[0], displayLocation].filter(Boolean).join(' ')
}

export const topAdAddressConfig: Pick<TopAdConfig, 'addressAttributes' | 'buildStringFromAddressItems'> = {
    addressAttributes: ['POSTCODE', 'COUNTRY', 'LOCATION'],
    buildStringFromAddressItems: topAddressBuildStringFromAddressItems,
}

const getTopAdConfig = (adTypeId: AdTypeId, topAdConfigMap: TopAdConfigMap) => {
    const adType = inverseAdTypeIdMap[adTypeId]
    if (!adType || !Object.keys(topAdConfigMap).includes(adType)) {
        return undefined
    }
    return topAdConfigMap[adType]
}

export const getTopAdContent = (topAd: AdvertSummary, topAdConfigMap: TopAdConfigMap) => {
    const formattedAttributes = formatAttributes(topAd.attributes)
    const topAdConfig = getTopAdConfig(topAd.adTypeId, topAdConfigMap)

    if (!topAdConfig) {
        return { teaser: '', price: '', address: '' }
    }

    const {
        teaserAttributes,
        buildStringFromTeaserItems,
        priceAttributes,
        buildStringFromPriceItems,
        addressAttributes,
        buildStringFromAddressItems,
    } = topAdConfig

    const buildStringOrJoinFromFormattedAttributes = buildStringOrJoin(formattedAttributes)

    const teaser = buildStringOrJoinFromFormattedAttributes(teaserAttributes, buildStringFromTeaserItems)
    const price = buildStringOrJoinFromFormattedAttributes(priceAttributes, buildStringFromPriceItems)
    const address = buildStringOrJoinFromFormattedAttributes(addressAttributes, buildStringFromAddressItems)

    return { teaser, price, address }
}

const buildStringOrJoin =
    (attributesList: FormattedAttributesList) => (attributeNames: AttributeName[], builderFn?: AttributeItemsStringBuilderFn) => {
        let attributeString

        if (builderFn) {
            const attributeItems = attributeNames.map((attribute) => getAttribute(attributesList.attribute, attribute))
            attributeString = builderFn(attributeItems)
        } else {
            const teaserAttributeValues = attributeNames.map((attribute) => getAttributeValue(attributesList.attribute, attribute))
            attributeString = teaserAttributeValues.filter(Boolean).join(', ')
        }
        return attributeString
    }
