import React, { useEffect } from 'react'
import { toast } from '@wh-components/core/Toast/Toast'
import { FromExpiredAdAlert } from '@bbx/search-journey/sub-domains/search/components/common/result-list/FromExpiredAdAlert/FromExpiredAdAlert'

export const useFromExpiredAd = (fromExpiredAdId: string | undefined) => {
    useEffect(() => {
        if (fromExpiredAdId) {
            toast(<FromExpiredAdAlert fromExpiredAdId={fromExpiredAdId} />, { type: 'info', autoClose: false })
        }
    }, [fromExpiredAdId])
}
