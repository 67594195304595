import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { AdBadges } from '@bbx/search-journey/common/components/AdvertSummary/AdBadges'
import { ImageGalleryData } from '@bbx/search-journey/common/components/ImageGallery/ImageGallery.settings'
import { VanillaImageGallery } from '@bbx/search-journey/common/components/ImageGallery/VanillaImageGallery/VanillaImageGallery'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { Box } from '@wh-components/core/Box/Box'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { ImageScaleModes } from '@wh/common/chapter/components/AdImage'
import { useIntersectionObserver } from '@wh/common/chapter/hooks/useIntersectionObserver'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { inverseAdTypeIdMap } from '@wh/common/chapter/types/AdType'
import { AdvertImage } from '@wh/common/chapter/types/AdvertImage'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { inverseVerticalIdMap } from '@wh/common/chapter/types/verticals'
import React, { FunctionComponent, useMemo, useRef } from 'react'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

export interface AdInMotionGalleryProps {
    advertSummary: AdvertSummary
    taggingData: TaggingData
}

const mapImageScaleMode = (imageScaleMode: string | undefined): ImageScaleModes => {
    switch (imageScaleMode) {
        case 'ASPECT_FILL':
            return 'cover'
        case 'ASPECT_FIT':
            return 'contain'
        default:
            return 'contain'
    }
}

export const AdInMotionGallery: FunctionComponent<AdInMotionGalleryProps> = ({ advertSummary, taggingData }) => {
    const imageScaleMode = mapImageScaleMode(
        getAttributeValue(advertSummary.attributes.attribute, 'UPSELLING_RESULT_LIST_AD_IN_MOTION_SCALE_MODE'),
    )
    const adsInMotionTrackingRef = useRef<HTMLDivElement>(null)
    const adsInMotionCountThreshold = 0.5
    const seoUrl = getSeoUrl(advertSummary)

    const changed = useRef<boolean>()

    const imageData: ImageGalleryData[] = useMemo(
        () =>
            advertSummary.advertImageList.advertImage.map((advertImage: AdvertImage, index) => ({
                alt: `Bild ${index + 1} von ${advertSummary.advertImageList.advertImage.length}${
                    advertImage.description ? ` - ${advertImage.description}` : ''
                }`,
                imageUrl: advertImage.referenceImageUrl,
                thumbnailUrl: advertImage.thumbnailImageUrl,
                link: seoUrl,
            })),
        [advertSummary.advertImageList.advertImage, seoUrl],
    )

    const isViewed = () => {
        logBdsEvent(advertSummary.id, 'atz-in-motion-viewed')
        callActionEvent('search_result_list_adinmotion_view', taggingData, { ad_id: `${advertSummary.id}` })
    }

    const [countAdInMotion] = useIntersectionObserver(
        adsInMotionTrackingRef,
        { triggerOnce: true, threshold: adsInMotionCountThreshold },
        [],
        isViewed,
    )

    const onManualChange = () => {
        if (!changed.current) {
            changed.current = true
            logBdsEvent(advertSummary.id, 'atz-in-motion-first-manual-image-change')
            callActionEvent('search_result_list_adinmotion_start', taggingData, { ad_id: `${advertSummary.id}` })
        }
    }

    const onClick = () => {
        logBdsEvent(advertSummary.id, 'atz-in-motion-clicked')
        callActionEvent('search_result_list_adinmotion_click', taggingData, { ad_id: `${advertSummary.id}` })
    }

    return (
        <Box ref={adsInMotionTrackingRef} data-count-as-viewed={countAdInMotion} width="100%">
            <Box position="relative" zIndex={1} top={30}>
                <AdBadges
                    advertStatus={advertSummary.advertStatus}
                    attributes={advertSummary.attributes.attribute}
                    position="absolute"
                    top="xs"
                    left="xs"
                />
            </Box>

            <VanillaImageGallery
                imageData={imageData}
                eventHandlers={{ onClick, onManualChange }}
                imageScaleMode={imageScaleMode}
                vertical={inverseVerticalIdMap[advertSummary.verticalId]}
                adType={inverseAdTypeIdMap[advertSummary.adTypeId]}
                imageBackgroundColor="palette.babyseal"
            />
        </Box>
    )
}
