import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { TestProps } from '@wh-components/core/common'
import { Alert } from '@wh-components/core/Alert/Alert'
import InfoIcon from '@wh-components/icons/AlertInfo'
import { SpaceProps } from '@wh-components/system/space'

interface InfoAlertProps extends TestProps, SpaceProps, PropsWithChildren {
    title?: string
    message?: ReactNode
}

export const InfoAlert: FunctionComponent<InfoAlertProps> = ({ title, message, testId, children, ...spaceProps }) => (
    <Alert title={title} variant="info" Icon={InfoIcon} testId={testId || 'alert-info'} {...spaceProps}>
        {children || message}
    </Alert>
)
