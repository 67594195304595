import { CdcAd, JobsWidgetType } from '@bbx/common/types/UserFeed'
import React, { FunctionComponent, RefObject, useContext } from 'react'
import { SpaceProps } from '@wh-components/system/space'
import { DmpStateContext } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { HorizontalSlider, ITEM_HEIGHT_JOBS } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'
import { findContextLinkWithIdFromArray } from '@bbx/common/types/contextLinks'
import { css } from 'styled-components'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { Text } from '@wh-components/core/Text/Text'
import { CdcJobAdvertSummaryComponent } from '@bbx/search-journey/common/components/AdvertSummary/CdcJobAdvertSummaryComponent'

interface JobsWidgetSliderProps {
    widget?: JobsWidgetType
    onClickShowAllTagging?: () => void
    onClickShowAllButtonTagging?: () => void
    onClickAdTagging?: (ad: CdcAd, adIndex: number) => void
    numberOfAdsLimit: number
    numberOfAdsToDisplay?: number
    visibilityTrackingRef?: RefObject<HTMLDivElement>
    onClose?: () => void
}

export const JobsWidgetSlider: FunctionComponent<JobsWidgetSliderProps & SpaceProps> = ({
    widget,
    onClickShowAllTagging,
    onClickShowAllButtonTagging,
    onClickAdTagging,
    numberOfAdsLimit,
    numberOfAdsToDisplay,
    visibilityTrackingRef,
    onClose,
    ...props
}) => {
    const { trackCustomDmpEvent } = useContext(DmpStateContext)
    const advertisingState = useContext(AdvertisingStateContext)
    const color = advertisingState.pageModifications.foregroundColors?.['startpage-standard-color']
    const titleColor = advertisingState.pageModifications.foregroundColors?.['startpage-title-color']

    if (!widget || widget.ads?.length === 0) {
        return null
    }

    return (
        <HorizontalSlider
            testIdPrefix="jobs-widget"
            heading={
                <Text lineClamp={1} fontWeight="bold" color={titleColor}>
                    Finde Jobs auf willhaben
                </Text>
            }
            linkText="Alle anzeigen"
            buttonText="Alle anzeigen"
            href={findContextLinkWithIdFromArray('resultListSeoNavigatorLink', widget.contextLinkList)?.uri}
            buttonHeight={ITEM_HEIGHT_JOBS}
            onClick={() => {
                onClickShowAllTagging?.()
                trackCustomDmpEvent('UserAction', { eventName: 'TapShowAllJobRecommendationWidget' })
            }}
            onClickButton={() => {
                onClickShowAllButtonTagging?.()
                trackCustomDmpEvent('UserAction', { eventName: 'TapShowAllJobRecommendationWidget' })
            }}
            css={css`
                ${(p) => p.theme.media.only.tablet} {
                    margin-right: -${(p) => p.theme.space.m}px;
                }
            `}
            numberOfAdsLimit={numberOfAdsLimit}
            numberOfAdsToDisplay={numberOfAdsToDisplay}
            visibilityTrackingRef={visibilityTrackingRef}
            onClose={onClose}
            {...props}
        >
            {widget.ads.slice(0, numberOfAdsLimit).map((ad, adIndex) => {
                return (
                    <CdcJobAdvertSummaryComponent
                        key={ad.description}
                        ad={ad}
                        testIdPrefix="jobs-widget"
                        isActive={true}
                        color={color}
                        onClickTaggingEvent={() => {
                            onClickAdTagging?.(ad, adIndex)
                        }}
                    />
                )
            })}
        </HorizontalSlider>
    )
}
