import React, { FunctionComponent } from 'react'
import { TestProps } from '@wh-components/core/common'
import { LinkGroup } from '@wh/common/chapter/types/SeoMetaData'
import { LinkVomit } from '@bbx/search-journey/common/components/LinkVomit/LinkVomit'
import { ContextLink } from '@bbx/common/types/contextLinks'

export type LinkVomitPossibleValue = { label: string; webLink?: Pick<ContextLink, 'relativePath'> | null }

interface PossibleValueLinkVomitProps extends TestProps {
    title: string
    possibleValues: LinkVomitPossibleValue[]
}

export const PossibleValueLinkVomit: FunctionComponent<PossibleValueLinkVomitProps> = ({ title, possibleValues, testId }) => {
    const vomitLinkGroup: LinkGroup = {
        title,
        links: possibleValues
            .map((value) => ({ name: value.label, link: value.webLink?.relativePath ?? '' }))
            .filter((value) => value.link !== ''),
    }

    if (vomitLinkGroup.links.length === 0) {
        return null
    }

    return <LinkVomit linkGroups={[vomitLinkGroup]} testId={testId} />
}
