import { useCallback, useEffect, useRef } from 'react'

export const useVanillaImageGallery = (nrOfImages: number, slideInterval: number | undefined, onManualChange?: () => void) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const imageRef = useRef<HTMLDivElement>(null)
    const interval = useRef<number>()

    const isScrolledToLastPosition = () => {
        const scrollLeft = scrollContainerRef.current?.scrollLeft ?? 0
        const clientWidth = scrollContainerRef.current?.clientWidth ?? 0
        const scrollWidth = scrollContainerRef.current?.scrollWidth ?? 0

        const randomError = 20
        return Math.abs(scrollLeft + clientWidth - scrollWidth) < randomError
    }

    const isScrolledToFirstPosition = () => scrollContainerRef.current?.scrollLeft === 0

    const handlePictureChange = useCallback(
        (changeTo: 'previous' | 'next') => {
            const scrollContainerWidth = scrollContainerRef.current?.offsetWidth ?? 0
            const imageWidth = imageRef.current?.offsetWidth ?? 0

            if (changeTo === 'previous') {
                isScrolledToFirstPosition()
                    ? scrollToLastImage(scrollContainerRef.current, scrollContainerWidth, nrOfImages)
                    : scrollToNextImage(scrollContainerRef.current, imageWidth)
            }

            if (changeTo === 'next') {
                isScrolledToLastPosition()
                    ? scrollToFirstImage(scrollContainerRef.current)
                    : scrollToPreviousImage(scrollContainerRef.current, imageWidth)
            }
        },
        [nrOfImages],
    )

    useEffect(() => {
        interval.current = setInterval(() => handlePictureChange('next'), slideInterval) as unknown as number

        if (scrollContainerRef?.current) {
            scrollContainerRef.current?.addEventListener(
                'touchmove',
                () => {
                    clearInterval(interval.current)
                    onManualChange?.()
                },
                { passive: true },
            )
        }

        return () => clearInterval(interval.current)
    }, [scrollContainerRef, onManualChange, handlePictureChange, slideInterval])
    return { scrollContainerRef, imageRef, interval, handlePictureChange }
}

const scrollToLastImage = (scrollContainer: HTMLDivElement | undefined | null, currentWidth: number, nrOfImages: number) =>
    scrollContainer?.scrollTo({ left: currentWidth * (nrOfImages - 1), top: 0, behavior: 'smooth' })

const scrollToNextImage = (scrollContainer: HTMLDivElement | undefined | null, currentWidth: number) =>
    scrollContainer?.scrollBy({ left: -currentWidth, top: 0, behavior: 'smooth' })

const scrollToFirstImage = (scrollContainer: HTMLDivElement | undefined | null) =>
    scrollContainer?.scrollTo({ left: 0, top: 0, behavior: 'smooth' })

const scrollToPreviousImage = (scrollContainer: HTMLDivElement | undefined | null, currentWidth: number) =>
    scrollContainer?.scrollBy({ left: currentWidth, top: 0, behavior: 'smooth' })
