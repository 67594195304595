import React, { FunctionComponent, useEffect } from 'react'
import { buildInitialSearchAgent } from '@bbx/search-journey/common/components/EditSearchAgentForm/searchAgentBuilder'
import {
    EditSearchAgentForm,
    EditSearchAgentFormValues,
} from '@bbx/search-journey/common/components/EditSearchAgentForm/EditSearchAgentForm'
import { createSearchAgentWithLink, getSearchAgentOptions } from '@bbx/search-journey/common/api/searchAgentApiClient'
import { InfoModal } from '@wh/common/chapter/components/Modals/InfoModal/InfoModal'
import { OPEN_CREATE_SEARCH_AGENT_MODAL_STORAGE_KEY } from '@wh/common/chapter/lib/localStorage'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { SearchAgentUIState } from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButton'
import { wrapInApiErrorIfNecessary } from '@wh/common/chapter/lib/errors'
import { toast } from '@wh-components/core/Toast/Toast'
import { storageAvailable } from '@wh/common/chapter/lib/storageAvailable'
import { ApiErrorAlert } from '@wh/common/chapter/components/Alerts/ApiErrorAlert'
import { isUserLoggedIn, useProfileData, useSearchAgentCount } from '@wh/common/chapter/components/GlobalStateProvider/GlobalStateProvider'
import { getNotificationByType } from '@bbx/common/lib/searchAgentHelper'
import { SearchAgentNotificationFrequency, SearchAgentNotificationType } from '@bbx/common/types/searchAgent'
import { getSearchAgentCount } from '@wh/common/chapter/api/userApiClient'

export interface CreateSearchAgentModalProps {
    searchAgentCreateLink: ContextLink | undefined
    searchAgentOptionsLink: ContextLink | undefined
    searchAgentUIState?: SearchAgentUIState
    setSearchAgentUIState: (value: SearchAgentUIState) => void
}

export const CreateSearchAgentModal: FunctionComponent<CreateSearchAgentModalProps> = ({
    searchAgentCreateLink,
    searchAgentUIState,
    setSearchAgentUIState,
    searchAgentOptionsLink,
}) => {
    const [profileData] = useProfileData()
    const [, setSearchAgentCount] = useSearchAgentCount()
    const loginId = isUserLoggedIn(profileData) ? profileData.loginId : undefined

    const refreshSearchAgentCount = async () => {
        const searchAgentCount = await getSearchAgentCount()
        setSearchAgentCount(searchAgentCount.activeCount)
    }

    useEffect(() => {
        if (storageAvailable('localStorage') && searchAgentOptionsLink) {
            const showModal = async () => {
                const openCreateSearchAgentModal = localStorage.getItem(OPEN_CREATE_SEARCH_AGENT_MODAL_STORAGE_KEY)
                if (openCreateSearchAgentModal && loginId) {
                    localStorage.removeItem(OPEN_CREATE_SEARCH_AGENT_MODAL_STORAGE_KEY)
                    if (searchAgentOptionsLink) {
                        const searchAgentOptions = await getSearchAgentOptions(searchAgentOptionsLink)
                        setSearchAgentUIState({ state: 'showModal', searchAgentOptions: searchAgentOptions })
                    } else {
                        setSearchAgentUIState({ state: 'showModal' })
                    }
                }
            }
            showModal()
        }
    }, [loginId, setSearchAgentUIState, searchAgentOptionsLink])

    if (!loginId) {
        return null
    }

    const onSubmit = async (values: EditSearchAgentFormValues) => {
        try {
            const searchAgent = buildInitialSearchAgent(loginId, values.description, false, SearchAgentNotificationFrequency.DAILY_3X)

            const emailNotification = getNotificationByType(searchAgent, SearchAgentNotificationType.EMAIL)
            if (emailNotification) {
                emailNotification.notificationFrequency = values.notificationFrequency
                emailNotification.activated = values.emailActivated
            }

            // we set the push notification frequency too, since it can not be updated later
            const pushNotification = getNotificationByType(searchAgent, SearchAgentNotificationType.PUSH)
            if (pushNotification) {
                pushNotification.notificationFrequency = values.notificationFrequency
            }

            searchAgent.description = values.description

            await createSearchAgentWithLink(searchAgentCreateLink!, searchAgent)

            setSearchAgentUIState({ state: 'idle' })
            toast('Dein Suchagent wurde gespeichert', {
                type: 'success',
            })

            // update search agent count for vertical menu
            await refreshSearchAgentCount()
        } catch (error) {
            const apiError = wrapInApiErrorIfNecessary(error)
            toast(<ApiErrorAlert error={apiError.errorResponse} />, { type: 'error', autoClose: false })
            setSearchAgentUIState({ state: 'idle' })
        }
    }

    return (
        <InfoModal
            fullScreen={{ phone: true, tablet: false }}
            isOpen={searchAgentUIState?.state === 'showModal'}
            onRequestClose={() => setSearchAgentUIState({ state: 'idle' })}
            header="Suchagent aktivieren"
            testId="create-search-agent-modal"
        >
            <EditSearchAgentForm
                onAbort={() => setSearchAgentUIState({ state: 'idle' })}
                onSubmit={onSubmit}
                searchAgentOptions={searchAgentUIState?.searchAgentOptions}
            />
        </InfoModal>
    )
}
