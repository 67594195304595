import { AdvertSummary, AdvertSummaryList } from '@bbx/common/types/ad-detail/AdvertSummary'
import { callActionEvent, callSelfPromotionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { useEmptyTaggingData } from '@bbx/search-journey/common/hooks/emptyTaggingData'
import { TaggingWidgetType } from '@bbx/search-journey/common/hooks/useWidgetTagging'
import { useEffect, useRef, useState } from 'react'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { logBdsEvent } from '@wh/common/chapter/api/bdsApiClient'
import { VerticalId, verticalIdMap, verticalTaggingNameMap } from '@wh/common/chapter/types/verticals'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'

export const useTrackVisibleTopAds = (topAds: AdvertSummaryList | undefined, verticalId: VerticalId) => {
    const visibilityTrackingRef = useRef<HTMLDivElement>(null)
    const [isInView] = useIsInView(visibilityTrackingRef, '-100px', undefined, false)
    const [tracked, setTracked] = useState<boolean>(false)
    const taggingData = useEmptyTaggingData()

    useEffect(() => {
        setTracked(false)
    }, [topAds])

    useEffect(() => {
        if (isInView && topAds && !tracked) {
            setTracked(true)
            trackTopAdsImpression('ad_widget_in_view', topAds, verticalId, taggingData)
        }
    }, [isInView, topAds, tracked, verticalId, taggingData])

    return { visibilityTrackingRef }
}

export const trackTopAdsImpression = (
    event: 'ad_widget_in_view' | 'ad_widget_loaded',
    topAds: AdvertSummaryList,
    verticalId: VerticalId,
    taggingData: TaggingData,
) => {
    callSelfPromotionEvent(event, taggingData, {
        ...getWidgetAndPage(verticalId),
        ...getListName(verticalId),
        adIds: topAds.advertSummary.map((advertSummary) => advertSummary.id),
    })
}

export const trackTopAdClicked = (topAd: AdvertSummary, index: number, taggingData: TaggingData | undefined, verticalId: VerticalId) => {
    const adId = topAd.id
    const adUuid = getAttributeValue(topAd.attributes.attribute, 'AD_UUID')
    const orgUuid = getAttributeValue(topAd.attributes.attribute, 'ORG_UUID')

    logBdsEvent(adId, 'top-atz-result-page-clicked')

    callActionEvent('ad_widget_ad_click', taggingData, {
        ...getWidgetAndPage(verticalId),
        ...getListName(verticalId),
        adId: adId,
        adIndex: index + 1,
        ad_id: adId,
    })
    callSelfPromotionEvent('ad_widget_click_interaction', taggingData, getWidgetAndPage(verticalId))
    callActionEvent('search_result_list_home_topad_click', taggingData, {
        ad_id: topAd.id,
        ad_uuid: adUuid,
        org_uuid: orgUuid,
    })
}

const getWidgetAndPage = (verticalId: number) => ({
    taggingId: verticalTaggingNameMap[verticalId],
    taggingWidgetType: 'TopAdsWidget' as TaggingWidgetType,
    page: 'ResultList',
    source: 'personal-space',
})

const getListName = (verticalId: VerticalId) => {
    switch (verticalId) {
        case verticalIdMap.BAP:
            return { listName: 'external-widget:top-ads:bap' }
        case verticalIdMap.MOTOR:
            return { listName: 'external-widget:top-ads:motor' }
        case verticalIdMap.ESTATE:
            return { listName: 'external-widget:top-ads:estate' }
    }

    return {}
}
