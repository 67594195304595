import React, { FunctionComponent } from 'react'
import {
    CreateSearchAgentButton,
    SearchAgentUIState,
} from '@bbx/search-journey/sub-domains/search/components/common/common/CreateSearchAgentButton/CreateSearchAgentButton'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'

interface ResultListBottomCreateSearchAgentButtonProps {
    searchAgentCreateLink: ContextLink | undefined
    searchAgentOptionsLink: ContextLink | undefined
    setSearchAgentUIState: React.Dispatch<React.SetStateAction<SearchAgentUIState>>
    currentSearchResult: SearchResult
}

export const ResultListBottomCreateSearchAgentButton: FunctionComponent<ResultListBottomCreateSearchAgentButtonProps> = ({
    currentSearchResult,
    searchAgentCreateLink,
    searchAgentOptionsLink,
    setSearchAgentUIState,
}) => {
    return (
        <Box
            paddingVertical="s"
            display={{ phone: 'none', tablet: currentSearchResult.rowsFound > 0 ? 'flex' : 'none' }}
            justifyContent="space-between"
            alignItems="center"
        >
            <Text fontSize="s">
                Wir benachrichtigen dich bei <b>neuen Anzeigen</b> automatisch!
            </Text>
            <CreateSearchAgentButton
                maxWidth={250}
                searchAgentCreateLink={searchAgentCreateLink}
                searchAgentOptionsLink={searchAgentOptionsLink}
                setSearchAgentUIState={setSearchAgentUIState}
                size="small"
                testId="create-search-agent-button-bottom"
                clickActionEvent="search_result_list_search_agent_click_bottom"
                taggingData={currentSearchResult.taggingData}
            />
        </Box>
    )
}
