import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { BrightSkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'

export const ResultListAdRowSkeleton: FunctionComponent<{ estimatedPhoneTitleLines: number; className?: string }> = ({
    estimatedPhoneTitleLines,
    className,
}) => (
    <Box height={{ desktop: 182 }} padding="m" borderBottom="owl" className={className}>
        <Box display={{ desktop: 'none' }} width="100%" marginBottom="s">
            <Box display="flex" gap="6px" flexDirection="column">
                <Box height={20 * estimatedPhoneTitleLines} width="100%">
                    <BrightSkeletonLine />
                </Box>
                <Box height={16} width="60%">
                    <BrightSkeletonLine />
                </Box>
            </Box>
        </Box>
        <Box display="flex">
            <Box height={{ phone: 131.25, tablet: 150 }} width={{ phone: 175, tablet: 200 }} marginRight={{ phone: 's', tablet: 'm' }}>
                <BrightSkeletonLine />
            </Box>
            <Box display="flex" flexDirection="column" flex={1}>
                <Box display="flex" gap={{ phone: 'xs', tablet: 's' }} flexDirection="column">
                    <Box height={24} width={{ phone: '50%', desktop: '100%' }}>
                        <BrightSkeletonLine />
                    </Box>
                    <Box height={18} width={{ phone: '40%', desktop: '60%' }}>
                        <BrightSkeletonLine />
                    </Box>
                </Box>
                <Box flex={1} />
                <Box
                    display="flex"
                    gap={{ phone: 'xs', tablet: 's' }}
                    flexDirection={{ phone: 'column-reverse', desktop: 'row' }}
                    alignItems={{ desktop: 'flex-end' }}
                    justifyContent="space-between"
                >
                    <Box height={18} width={150}>
                        <BrightSkeletonLine />
                    </Box>
                    <Box height={26} width={90}>
                        <BrightSkeletonLine />
                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>
)
