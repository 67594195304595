import React, { FunctionComponent } from 'react'
import { Tooltip } from '@wh-components/core/Tooltip/Tooltip'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import BumpIcon from '@wh-components/icons/Bump'

export const BumpedTooltip: FunctionComponent = () => {
    return (
        <Tooltip content={<Text>Vorgereihte Anzeige</Text>} interactive={true}>
            <Box display="inline-flex" marginRight="xs" alignItems="center" position="relative" top={2}>
                <BumpIcon size={16} aria-hidden={true} />
            </Box>
        </Tooltip>
    )
}
