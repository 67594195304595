import { SavedAdsAdIdsRecord } from '@bbx/common/types/savedAds'
import { SavedAdsUiState } from '@bbx/search-journey/common/components/SaveAd/SaveAdModal'
import { useCallback, useEffect, useState } from 'react'
import { getAllSavedAdsAdIds } from '@bbx/search-journey/common/api/savedAdsListApiClient'

export const useResultListSavedAds = (loginId: number | undefined) => {
    const [savedAdsUiState, setSavedAdsUiState] = useState<SavedAdsUiState>({ state: 'idle' })
    const [savedAdsIdList, setSavedAdsIdList] = useState<SavedAdsAdIdsRecord>({})

    useEffect(() => {
        async function loadSavedAdsAdIds() {
            if (loginId) {
                setSavedAdsIdList(await getAllSavedAdsAdIds(loginId))
            }
        }

        loadSavedAdsAdIds()
        /* savedAdsUiState is here to reload the saved-ads when saving an ad in the <SaveAdModal> */
    }, [loginId, savedAdsUiState])

    const updateSavedAdsIdList = useCallback(
        (updatedAdStatus: { id: string; status: boolean }) => {
            setSavedAdsIdList((oldSavedAdsIdList) => {
                return { ...oldSavedAdsIdList, [updatedAdStatus.id]: updatedAdStatus.status }
            })
        },
        [setSavedAdsIdList],
    )

    return { savedAdsUiState, setSavedAdsUiState, savedAdsIdList, updateSavedAdsIdList }
}
